
.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  

  .title .left,
  .title .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title .right p {
    margin: 0;
    font-size: calc(16px + 0.5vw);
    line-height: 1.6;
    text-align: justify;
    hyphens: auto;
  }
  .title .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px; 
    font-family: 'Alex Brush', sans-serif;
  }
  
  .title .left .large-text {
    font-size: calc(18px + 0.5vw);
    text-align: center;
  }
  
  .title .left .small-text {
    font-size: calc(14px + 0.5vw);
    text-align: center;
  }

  .charente {
    color: rgb(11, 100, 194);
  }
  
  .maritime {
    color: rgb(21, 228, 135);
  }
  
  .la {
    color: yellow;
  }
  
  .rochelle {
    color: yellow;
  }
  
  

@media screen and (max-width: 480px) {
    .title .left .large-text {
        font-size: calc(14px + 2vw);
      }
    
      .title .left .small-text {
        font-size: calc(10px + 2vw);
      }
  
    .title .right p {
      font-size: calc(4px + 2vw);
      text-align: justify;
    }
  }
  
  @media screen and (max-width: 767px) and (orientation: landscape) {
    .title .left .large-text {
      font-size: calc(8px + 1.5vw);
    }
  
    .title .left .small-text {
      font-size: calc(6px + 1.5vw);
    }
  
    .title .right p {
      font-size: calc(4px + 1.5vw);
    }
    .title {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 150px 20px 20px 20px; 
      }
  }