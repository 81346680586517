@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.footer-links-container {
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    font-family: 'Dancing Script', cursive;
}

.footer-links {
    display: flex;
    justify-content: center;
    height: 70px;
}

.footer-link {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    margin-top: 15px;
    margin-right: 200px;
    margin-left: 200px;
    font-size: clamp(14px, 3vw, 25px); 
    white-space: nowrap; 
}
